/**
 * ID компании FuelUp
 */
export const FUELUP_COMPANY_ID = '0698081c-2e10-441d-b89d-db34cca2a4d9';

/**
 * Стандартная задержка для lodash/throttle
 */
export const THROTTLE_DELAY = 1000;

/**
 * Стандартная задержка для lodash/debounce
 */
export const DEBOUNCE_DELAY = 400;

/**
 * Строчный индикатор ошибки
 */
export const ERROR_STRING = '/error/';

/**
 * Количество записей на одной странице по умолчанию
 */
export const DEFAULT_PAGE_SIZE = 10;

/**
 * Время отключения кнопки "Копировать" при нажатии
 */
export const COPY_BUTTON_DISABLE_DELAY = 2000;
