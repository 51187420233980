import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StationsMap = _resolveComponent("StationsMap")!
  const _component_UiPage = _resolveComponent("UiPage")!

  return (_openBlock(), _createBlock(_component_UiPage, {
    title: "Карта АЗС",
    style: {"height":"calc(100% - 128px)"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_StationsMap)
    ]),
    _: 1
  }))
}