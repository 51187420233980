import { notification } from 'ant-design-vue';
import { h, nextTick } from 'vue';
import { UiLink } from '@/components/uikit';

export async function useCookieConsent() {
  const value = localStorage.getItem('cookie-consent');
  if (value !== '1') {
    await nextTick();
    notification.open({
      key: 'cookie-consent',
      message: '',
      description: h('div', {}, [
        h('span', {}, [
          'Заправить Авто обрабатывает cookie-файлы c целью персонализации сервисов и удобства пользования веб-сайтом. Вы можете запретить обработку cookie-файлов в настройках браузера. Пожалуйста, ознакомьтесь с',
        ]),
        h(UiLink, { href: 'https://zapravit-avto.ru/cookie', target: '_blank' }, [' офертой ']),
        h('span', {}, ['использования cookie-файлов.']),
      ]),
      placement: 'bottomRight',
      duration: 0,
      onClose: () => {
        localStorage.setItem('cookie-consent', '1');
      },
    });
  }
}
