
import { defineComponent } from 'vue';
import ripple from '@/plugins/ripple';
import { useAnalytics } from '@/plugins/analytics';

export default defineComponent({
  name: 'SideBanner',
  directives: { ripple },
  setup() {
    const analytics = useAnalytics();
    return { analytics };
  },
});
