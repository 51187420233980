// noinspection SpellCheckingInspection

import { Router } from 'vue-router';
import { nextTick } from 'vue';

/**
 * Параметры инициализации аналитики
 */
export type AnalyticsOptions = {
  router: Router;
  dpmId?: string;
  yandexCounters?: number[];
  trackOnNextTick?: boolean;
  debugEnabled?: boolean;
  skipTracking?: boolean;
};

/**
 * Скрипт Яндекс.Метрики
 */
const YANDEX_SCRIPT = 'https://mc.yandex.ru/metrika/tag.js';

/**
 * Скрипт DMPKit
 */
const DMP_SCRIPT = 'https://dmp.sbermarketing.ru/tm.js';

/**
 * Функция window.ym()
 */
type YandexTrigger = (...args: unknown[]) => void;

/**
 * Дополнительные свойства window.ym
 */
type YandexParams = {
  a: unknown[][];
  l: number;
};

/**
 * window.dataLayer
 */
export interface DataLayerObject extends Record<string, unknown> {
  event?: string;
}

/**
 * Определить дополнительные поля для window
 */
interface WindowWithAnalyticsObject extends Window {
  ym: YandexTrigger & YandexParams;
  dataLayer: DataLayerObject[];
}
declare const window: WindowWithAnalyticsObject;

/**
 * Данные для события
 */
type AdditionalEventData = Record<string, unknown>;

/**
 * Собственно класс аналитики
 */
export class Analytics {
  private readonly yandexCounters: number[];
  private readonly trackOnNextTick: boolean;
  private readonly debugEnabled: boolean;
  private readonly skipTracking: boolean;

  constructor(options: AnalyticsOptions) {
    this.trackOnNextTick = !!options.trackOnNextTick;
    this.debugEnabled = !!options.debugEnabled;
    this.skipTracking = !!options.skipTracking;
    window.dataLayer = window.dataLayer ?? [];
    void this.initVueRouter(options.router);
    this.yandexCounters = options.yandexCounters || [];
    this.yandexInit();
    options.dpmId && this.dpmInit(options.dpmId);
  }

  private readonly actions = {
    registrationBegin: this.registrationBegin,
    registrationSuccess: this.registrationSuccess,
    loginSuccess: this.loginSuccess,
    logoutSuccess: this.logoutSuccess,
    openAuth: null,
    changeDriverLimits: null,
    hideAddDriver: null,
    hideAddBalance: null,
    clickLoginButton: this.clickLoginButton,
    clickRegistrationButton: this.clickRegistrationButton,
    seeSmsCodeForm: this.seeSmsCodeForm,
    seeCompanyInfoDialog: this.seeCompanyInfoDialog,
    seeCompanyInfoEditForm: this.seeCompanyInfoEditForm,
    fillCompanyInfoDialogError: this.fillCompanyInfoDialogError,
    fillCompanyInfoDialogSuccess: this.fillCompanyInfoDialogSuccess,
    fillCompanyInfoEditFormSuccess: this.fillCompanyInfoEditFormSuccess,
    fillCompanyInfoEditFormError: this.fillCompanyInfoEditFormError,
    clickBalanceIncreaseButton: this.clickBalanceIncreaseButton,
    seeBalanceIncreaseForm: this.seeBalanceIncreaseForm,
    clickBalanceIncreaseSubmitButton: this.clickBalanceIncreaseSubmitButton,
    balanceIncreaseSuccess: this.balanceIncreaseSuccess,
    balanceIncreaseError: this.balanceIncreaseError,
    balanceCompanyContactsSuccess: this.balanceCompanyContactsSuccess,
    balanceCompanyContactsError: this.balanceCompanyContactsError,
    seeAddForm: this.seeAddForm,
    addDriverSuccess: this.addDriverSuccess,
    addDriverError: this.addDriverError,
    addDriverToDepartmentSuccess: this.addDriverToDepartmentSuccess,
    addDriverToDepartmentError: this.addDriverToDepartmentError,
    addCarSuccess: this.addCarSuccess,
    addCarError: this.addCarError,
    editCarSuccess: this.editCarSuccess,
    editCarError: this.editCarError,
    addCarToDepartmentSuccess: this.addCarToDepartmentSuccess,
    addCarToDepartmentError: this.addCarToDepartmentError,
    addDepartmentSuccess: this.addDepartmentSuccess,
    addDepartmentError: this.addDepartmentError,
    editDepartmentSuccess: this.editDepartmentSuccess,
    editDepartmentError: this.editDepartmentError,
    addUserSuccess: this.addUserSuccess,
    addUserError: this.addUserError,
    clickDriverAddButton: this.clickDriverAddButton,
    clickCarAddButton: this.clickCarAddButton,
    clickUserAddButton: this.clickUserAddButton,
    clickDepartmentAddButton: this.clickDepartmentAddButton,
    driverLimitsChangeSuccess: this.driverLimitsChangeSuccess,
    driverLimitsChangeError: this.driverLimitsChangeError,
  };

  /**
   * Инициалицаия vue-router
   */
  async initVueRouter(router: Router) {
    if (!router) {
      return;
    }
    const vueRouterModule: typeof import('vue-router') = await import('vue-router');
    router.afterEach(async (to, from, failure) => {
      if (typeof to.name !== 'string') {
        return;
      }
      if (to.fullPath === from?.fullPath) {
        return;
      }
      const { name } = to;
      if (vueRouterModule.isNavigationFailure(failure, vueRouterModule.NavigationFailureType.aborted)) {
        if (this.debugEnabled) {
          console.log(`[Analytics]: '${name}' not tracked due to navigation aborted`);
        }
      } else if (vueRouterModule.isNavigationFailure(failure, vueRouterModule.NavigationFailureType.cancelled)) {
        if (this.debugEnabled) {
          console.log(`[Analytics]: '${name}' not tracked due to navigation cancelled`);
        }
      }
      const additionalEventData: AdditionalEventData = {
        ...to.meta?.gtmAdditionalEventData,
      };
      let fullUrl = router.options?.history?.base ?? '';
      if (!fullUrl.endsWith('/')) {
        fullUrl += '/';
      }
      fullUrl += to.fullPath.startsWith('/') ? to.fullPath.slice(1) : to.fullPath;
      if (this.trackOnNextTick) {
        void nextTick(() => {
          this.trackView(name, fullUrl, additionalEventData);
        });
      } else {
        this.trackView(name, fullUrl, additionalEventData);
      }
    });
  }

  /**
   * Отслеживание событий vue-router
   */
  trackView(screenName: string, path: string, additionalEventData: AdditionalEventData) {
    if (this.debugEnabled) {
      console.log('[Analytics]::trackView', screenName, path, additionalEventData);
    }
    window.dataLayer.push({
      ...additionalEventData,
      event: 'content-view',
      'content-name': path,
      'content-view-name': screenName,
    });
    if (this.skipTracking) {
      return;
    }
    this.yandexTrigger('hit', path);
  }

  /**
   * Инициализация Яндекс.Метрики
   */
  yandexInit() {
    if (this.yandexCounters.length === 0) {
      return;
    }
    if (!this.skipTracking) {
      // скрипт регистрации события
      window.ym =
        window.ym ||
        ((...args: unknown[]) => {
          (window.ym.a = window.ym.a || []).push(args);
        });

      window.ym.l = +new Date();

      // Тут проверка, есть ли уже добавленный файл (нужна ли?)
      for (let j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === YANDEX_SCRIPT) {
          return;
        }
      }

      // Добавляем скрипт в шапку
      const script = document.createElement('script');
      script.async = true;
      script.src = YANDEX_SCRIPT;
      document.head.appendChild(script);
    }

    this.yandexTrigger('init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
  }

  /**
   * Передача события в Яндекс.Метрику
   */
  yandexTrigger(event: string, ...args: unknown[]) {
    this.yandexCounters.forEach((counter) => {
      if (this.debugEnabled) {
        console.log('[Analytics]::yandexTrigger', counter, event, ...args);
      }
      window.ym?.(counter, event, ...args);
    });
  }

  /**
   * Передача события в Яндекс.Метрику
   */
  yandexCounter(counter: number, event: string, ...args: unknown[]) {
    if (this.debugEnabled) {
      console.log('[Analytics]::yandexCounter', counter, event, ...args);
    }
    window.ym?.(counter, event, ...args);
  }

  dpmInit(id: string) {
    window.dataLayer.push({ 'tm.start': +new Date(), event: 'tm.js' });
    if (!this.skipTracking) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `${DMP_SCRIPT}?id=${id}&l=dataLayer`;
      document.head.appendChild(script);
    }
    if (this.debugEnabled) {
      console.log('[Analytics]::dpmInit', id);
    }
  }

  /**
   * Обработка дополнительных событий
   */
  handle(eventName: keyof typeof this.actions, payload?: { [key: string]: unknown }) {
    if (this.debugEnabled) {
      console.log('[Analytics]::handleEvent', { event: eventName, payload });
    }
    const action: (() => void) | ((payload?: { [key: string]: unknown }) => void) | null = this.actions[eventName];
    action && action(payload);
  }

  private clickLoginButton(payload?: { errorMessage: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: 'tk',
      action: 'enterClick',
      label: 'Войти',
      details: {
        message: payload?.errorMessage,
      },
    });
  }

  private clickRegistrationButton() {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: 'tk',
      action: 'enterClick',
      label: 'Зарегистрироваться',
      details: {},
    });
  }

  private seeSmsCodeForm(payload?: { errorMessage: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: 'tk',
      action: 'enterView',
      label: 'Смс',
      details: {
        message: payload?.errorMessage,
      },
    });
  }

  private registrationBegin() {
    window.ym?.(92706008, 'reachGoal', 'lk_registration_step_1'); // https://tracker.yandex.ru/DEV-537
  }

  private registrationSuccess(payload?: { id: string; method: string }) {
    window.ym?.(92706008, 'reachGoal', 'lk_registration_step_2'); // https://tracker.yandex.ru/DEV-537
    window.dataLayer.push({
      event: 'fuelup_event',
      category: 'tk',
      action: 'regSuccess',
      label: payload?.method,
      user_id: payload?.id || '',
    });
  }

  private loginSuccess(payload?: { id: string; method: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: 'tk',
      action: 'enterSuccess',
      label: payload?.method,
      user_id: payload?.id || '',
    });
  }

  private logoutSuccess(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkQuit',
      label: 'Меню',
      user_id: payload?.companyId,
    });
  }

  private seeCompanyInfoDialog(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormView',
      label: 'Информация юридического лица',
      user_id: payload?.companyId,
    });
  }

  private fillCompanyInfoDialogSuccess(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Информация юридического лица',
      user_id: payload?.companyId,
    });
  }

  private fillCompanyInfoDialogError(payload?: { companyId: string; companyType: string; errors: string[] }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Информация юридического лица',
      user_id: payload?.companyId,
      details: {
        message: payload?.errors.join('; '),
      },
    });
  }

  private seeCompanyInfoEditForm(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormView',
      label: 'Информация юридического лица',
      user_id: payload?.companyId,
    });
  }

  private fillCompanyInfoEditFormSuccess(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: ' lkFormSuccess',
      label: 'Информация юридического лица',
      user_id: payload?.companyId,
    });
  }

  private fillCompanyInfoEditFormError(payload?: { companyId: string; companyType: string; errors: string[] }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Информация юридического лица',
      user_id: payload?.companyId,
      details: {
        message: payload?.errors.join('; '),
      },
    });
  }

  private clickBalanceIncreaseButton(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkClick',
      label: 'Пополнить баланс',
      user_id: payload?.companyId,
    });
  }

  private seeBalanceIncreaseForm(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormView',
      label: 'Пополнение счета',
      user_id: payload?.companyId,
    });
  }

  private clickBalanceIncreaseSubmitButton(payload?: { companyId: string; companyType: string; sum: number }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Пополнение счета',
      user_id: payload?.companyId,
      details: {
        method: 'Выставить счёт',
        sum: payload?.sum,
        transactionID: '',
      },
    });
  }

  private balanceIncreaseSuccess(payload?: {
    companyId: string;
    companyType: string;
    sum: number;
    transactionId: string;
  }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormPayment',
      label: 'Пополнение счета',
      user_id: payload?.companyId,
      details: {
        method: 'Выставить счёт',
        sum: payload?.sum,
        transactionID: payload?.transactionId,
      },
    });
  }

  private balanceIncreaseError(payload?: {
    companyId: string;
    companyType: string;
    sum: number;
    transactionId: string;
    errors: string[];
  }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Пополнение счета',
      user_id: payload?.companyId,
      details: {
        method: 'Выставить счёт',
        sum: payload?.sum,
        transactionID: payload?.transactionId,
        message: payload?.errors.join('; '),
      },
    });
  }

  private seeAddForm(payload?: { companyId: string; companyType: string; formName: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormView',
      label: payload?.formName,
      user_id: payload?.companyId,
    });
  }

  private addDriverSuccess(payload?: {
    companyId: string;
    companyType: string;
    driverId: string;
    limitDay: string;
    limitWeek: string;
    limitMonth: string;
    departmentId: string;
    isQuantity: boolean;
  }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Добавить водителя',
      user_id: payload?.companyId,
      details: {
        driverID: payload?.driverId,
        limitDay: payload?.limitDay,
        limitWeek: payload?.limitWeek,
        limitMonth: payload?.limitMonth,
        departmentID: payload?.departmentId,
        currency: payload?.isQuantity ? 'LITER' : 'RUB',
      },
    });
  }

  private addDriverError(payload?: {
    companyId: string;
    companyType: string;
    driverId: string;
    limitDay: string;
    limitWeek: string;
    limitMonth: string;
    departmentId: string;
    isQuantity: boolean;
    errors: string[];
  }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Добавить водителя',
      user_id: payload?.companyId,
      details: {
        driverID: payload?.driverId,
        limitDay: payload?.limitDay,
        limitWeek: payload?.limitWeek,
        limitMonth: payload?.limitMonth,
        departmentID: payload?.departmentId,
        currency: payload?.isQuantity ? 'LITER' : 'RUB',
        message: payload?.errors.join('; '),
      },
    });
  }

  private addCarSuccess(payload?: { companyId: string; companyType: string; carId: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Добавить автомобиль',
      user_id: payload?.companyId,
      details: {
        carID: payload?.carId,
      },
    });
  }

  private addCarError(payload?: { companyId: string; companyType: string; carId: string; errors: string[] }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Добавить автомобиль',
      user_id: payload?.companyId,
      details: {
        carID: payload?.carId,
        message: payload?.errors.join('; '),
      },
    });
  }

  private editCarSuccess(payload?: { companyId: string; companyType: string; carId: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Изменить автомобиль',
      user_id: payload?.companyId,
      details: { carID: payload?.carId },
    });
  }

  private editCarError(payload?: { companyId: string; companyType: string; carId: string; errors: string[] }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Изменить автомобиль',
      user_id: payload?.companyId,
      details: {
        carID: payload?.carId,
        message: payload?.errors.join('; '),
      },
    });
  }

  private addDepartmentSuccess(payload?: { companyId: string; companyType: string; departmentId: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Добавить отдел',
      user_id: payload?.companyId,
      details: {
        departmentID: payload?.departmentId,
      },
    });
  }

  private addDepartmentError(payload?: {
    companyId: string;
    companyType: string;
    departmentId: string;
    errors: string[];
  }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Добавить отдел',
      user_id: payload?.companyId,
      details: {
        departmentID: payload?.departmentId,
        message: payload?.errors.join('; '),
      },
    });
  }

  private editDepartmentSuccess(payload?: { companyId: string; companyType: string; departmentId: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Изменить отдел',
      user_id: payload?.companyId,
      details: {
        departmentID: payload?.departmentId,
      },
    });
  }

  private editDepartmentError(payload?: {
    companyId: string;
    companyType: string;
    departmentId: string;
    errors: string[];
  }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Изменить отдел',
      user_id: payload?.companyId,
      details: {
        departmentID: payload?.departmentId,
        message: payload?.errors.join('; '),
      },
    });
  }

  private addUserSuccess(payload?: { companyId: string; companyType: string; userId: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Добавить пользователя',
      user_id: payload?.companyId,
      details: {
        userID: payload?.userId,
      },
    });
  }

  private addUserError(payload?: { companyId: string; companyType: string; errors: string[] }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Добавить пользователя',
      user_id: payload?.companyId,
      details: {
        userID: '',
        message: payload?.errors.join('; '),
      },
    });
  }

  private clickDriverAddButton(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkClick',
      label: 'Добавить водителя',
      user_id: payload?.companyId,
    });
  }

  private clickCarAddButton(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkClick',
      label: 'Добавить автомобиль',
      user_id: payload?.companyId,
    });
  }

  private clickUserAddButton(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkClick',
      label: 'Добавить пользователя',
      user_id: payload?.companyId,
    });
  }

  private clickDepartmentAddButton(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkClick',
      label: 'Добавить отдел',
      user_id: payload?.companyId,
    });
  }

  private balanceCompanyContactsSuccess(payload?: { companyId: string; companyType: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Заполните контакты для взаиморасчетов и закрывающих документов',
      user_id: payload?.companyId,
    });
  }

  private balanceCompanyContactsError(payload?: { companyId: string; companyType: string; errors: string[] }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Заполните контакты для взаиморасчетов и закрывающих документов',
      user_id: payload?.companyId,
      details: {
        message: payload?.errors.join('; '),
      },
    });
  }

  private addCarToDepartmentSuccess(payload?: { companyId: string; companyType: string; carId: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Добавить автомобиль',
      user_id: payload?.companyId,
      details: {
        carID: payload?.carId,
      },
    });
  }

  private addCarToDepartmentError(payload?: {
    companyId: string;
    companyType: string;
    carId: string;
    errors: string[];
  }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Добавить автомобиль',
      user_id: payload?.companyId,
      details: {
        carID: payload?.carId,
        message: payload?.errors.join('; '),
      },
    });
  }

  private addDriverToDepartmentSuccess(payload?: { companyId: string; companyType: string; driverId: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Добавить в отдел',
      user_id: payload?.companyId,
      details: {
        driverID: payload?.driverId,
      },
    });
  }

  private addDriverToDepartmentError(payload?: {
    companyId: string;
    companyType: string;
    driverId: string;
    errors: string[];
  }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Добавить в отдел',
      user_id: payload?.companyId,
      details: {
        driverID: payload?.driverId,
        message: payload?.errors.join('; '),
      },
    });
  }

  private driverLimitsChangeSuccess(payload?: { companyId: string; companyType: string; driverId: string }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormSuccess',
      label: 'Лимиты водителя',
      user_id: payload?.companyId,
      details: {
        driverID: payload?.driverId,
      },
    });
  }

  private driverLimitsChangeError(payload?: {
    companyId: string;
    companyType: string;
    driverId: string;
    errors: string[];
  }) {
    window.dataLayer.push({
      event: 'fuelup_event',
      category: payload?.companyType,
      action: 'lkFormError',
      label: 'Лимиты водителя',
      user_id: payload?.companyId,
      details: {
        driverID: payload?.driverId,
        message: payload?.errors.join('; '),
      },
    });
  }
}
