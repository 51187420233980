
import { defineComponent, PropType } from 'vue';
import AResult from 'ant-design-vue/es/result';

export default defineComponent({
  components: {
    AResult,
  },
  props: {
    status: {
      type: String as PropType<'success' | 'error' | 'info' | 'warning' | '404' | '403' | '500'>,
      default: 'info',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      //
    };
  },
});
