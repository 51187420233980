import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AResult = _resolveComponent("AResult")!

  return (_openBlock(), _createBlock(_component_AResult, {
    status: _ctx.status,
    title: _ctx.title,
    subTitle: _ctx.subTitle
  }, _createSlots({ _: 2 }, [
    (_ctx.$slots.extra)
      ? {
          name: "extra",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "extra")
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.$slots.icon)
      ? {
          name: "icon",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icon")
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["status", "title", "subTitle"]))
}