import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AInput = _resolveComponent("AInput")!
  const _component_UiText = _resolveComponent("UiText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'ant-form-item-has-error': _ctx.error }, "input-wrapper"])
  }, [
    _createVNode(_component_AInput, {
      ref: "inputRef",
      value: _ctx.localInputValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localInputValue) = $event)),
      type: "text",
      maxlength: _ctx.maxlength,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      allowClear: _ctx.allowClear,
      step: _ctx.step,
      size: _ctx.size,
      onPressEnter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('pressEnter')))
    }, {
      prefix: _withCtx(() => [
        _renderSlot(_ctx.$slots, "prefix", {}, undefined, true)
      ]),
      suffix: _withCtx(() => [
        _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["value", "maxlength", "placeholder", "disabled", "allowClear", "step", "size"]),
    (_ctx.error && _ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_UiText, {
          key: 0,
          class: "input-error-message",
          type: "danger"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}