
import { defineComponent, PropType } from 'vue';
import AAlert, { AlertType } from 'ant-design-vue/es/alert';

export default defineComponent({
  emits: ['close'],
  components: {
    AAlert,
  },
  props: {
    /**
     * Тип предупреждения
     */
    type: {
      type: String as PropType<AlertType>,
      default: 'info',
    },
    /**
     * Текст сообщения
     */
    message: {
      type: String,
      default: '',
    },
    /**
     * Предупреждение можно закрыть
     */
    closable: {
      type: Boolean,
      default: false,
    },
    /**
     * Показывать иконку
     */
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
});
