import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ATour = _resolveComponent("ATour")!

  return (_openBlock(), _createBlock(_component_ATour, {
    open: _ctx.open,
    steps: _ctx.steps,
    current: _ctx.current,
    rootClassName: { 'ant-tour-persistant': !_ctx.closable },
    placement: _ctx.placement,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
    "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:current', $event)))
  }, null, 8, ["open", "steps", "current", "rootClassName", "placement"]))
}