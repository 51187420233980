
import { defineComponent, PropType } from 'vue';
import ATour, { TourProps } from 'ant-design-vue/es/tour';

export default defineComponent({
  emits: ['close', 'update:current'],
  components: {
    ATour,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array as PropType<TourProps['steps']>,
      required: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
  },
  setup() {
    return {
      //
    };
  },
});
