import { api } from '@/store';
import { q } from '@/store/api';
import { getAuthToken } from '@/store/auth';
import {
  CompanyUserAccess,
  DriverStatus,
  Maybe,
  DriverLimits,
  Driver,
  GetDriversListPageQuery,
  GetDriversQuery,
  DriversGetDriverPageQueryVariables,
  DriversRefreshDriverPageQueryVariables,
  DriversAddDriverMutationVariables,
  GetDriverBalanceHistoryQueryVariables,
} from '@/gql/graphql';

import { graphql } from '@/gql';
import useQuery from '@/helpers/useQuery';

export function getDriver({
  id,
  partnerId,
  companyId,
}: {
  id: string;
  partnerId: string;
  companyId: string;
}): Promise<GetDriverResult> {
  return api
    .graphql({
      query: q`
      query getDriver ($companyId: ID!, $partnerId: ID!, $id: ID!) {
        cabinet {
          company(id: $companyId) {
            driver(id: $id, partnerId: $partnerId) {
              id
              name
              phone
              created
              partnerCars {
                partner { id name}
                car { id number}
                limits { minLeft blocked day week month isQuantity fuels regions limitsType balance }
                isActive
              }
            }
            userAccess {
              partnerParentId
              partnerId
              name
              role
            }
          }
        }
      }
    `,
      variables: { id, partnerId, companyId, filter: {} },
      token: getAuthToken(),
    })
    .then((result) => {
      return result.cabinet?.company;
    });
}

type GetDriverResult = {
  driver: Driver;
  userAccess: CompanyUserAccess;
};

export function saveDriver({
  id,
  companyId,
  partnerId,
  driver,
}: {
  id: string;
  partnerId: string;
  driver: UpdatedDriver;
  companyId: string;
}) {
  return api
    .graphql({
      query: q`
        mutation saveDriver ($id: ID!, $partnerId: ID!, $companyId: ID!, $driver: DriverEditData!) {
          saveDriver (id: $id, partnerId: $partnerId, companyId: $companyId, driver: $driver) {
            id
          }
        }
      `,
      variables: { id, companyId, partnerId, driver },
      token: getAuthToken(),
    })
    .then((result) => result.saveDriver);
}

export function removeDriver({ companyId, driverId }: { companyId: string; driverId: string }) {
  return api
    .graphql({
      query: q`
      mutation removeDriver ($companyId: ID!, $driverId: ID!) {
        removeDriver (companyId: $companyId, id: $driverId)
      }
    `,
      variables: { companyId, driverId },
      token: getAuthToken(),
    })
    .then((result) => result.removeDriver);
}

export type UpdatedDriver = {
  id?: string;
  name?: string;
  phone?: string;
  created?: string;
  partnerId?: string;
  partnerDriverId?: string;
  partnerName?: string;
  isActive?: boolean;
  limits?: DriverLimits;
};

export const GET_DRIVERS_QUERY = graphql(`
  query getDrivers($companyId: ID, $partnerId: ID, $query: String, $status: DriverStatus, $page: Int, $size: Int) {
    cabinet {
      company(id: $companyId, partnerId: $partnerId) {
        drivers(query: $query, status: $status, page: $page, size: $size) {
          hasAny
          count
          items {
            id
            name
            phone
            created
            partnerId
            partnerDriverId
            partnerName
            isActive
            limits {
              minLeft
              blocked
              day
              week
              month
              isQuantity
              fuels
              regions
              limitsType
              balance
            }
            car {
              id
              number
            }
          }
        }
        userAccess {
          partnerParentId
          partnerId
          name
          role
        }
      }
    }
  }
`);

export function getDrivers({
  companyId,
  departmentId,
  driverStatus,
  searchQuery,
  page,
  size,
}: {
  companyId: string;
  departmentId?: string;
  driverStatus?: Maybe<DriverStatus>;
  searchQuery?: string;
  page?: number;
  size?: number;
}) {
  return useQuery(GET_DRIVERS_QUERY, {
    companyId,
    partnerId: departmentId,
    status: driverStatus,
    query: searchQuery,
    page,
    size,
  }).then((result) => result.cabinet?.company);
}

export type GetDriversResult = NonNullable<NonNullable<GetDriversQuery['cabinet']>['company']>;

export const GET_DRIVERS_LIST_PAGE_QUERY = graphql(`
  query getDriversListPage(
    $companyId: ID
    $partnerId: ID
    $status: DriverStatus
    $query: String
    $page: Int
    $size: Int
  ) {
    cabinet {
      company(id: $companyId, partnerId: $partnerId) {
        drivers(query: $query, status: $status, page: $page, size: $size) {
          hasAny
          count
          items {
            id
            name
            phone
            created
            partnerId
            partnerDriverId
            partnerName
            isActive
            limits {
              minLeft
              blocked
              day
              week
              month
              isQuantity
              fuels
              regions
              limitsType
              balance
            }
            car {
              id
              number
            }
          }
        }
        userAccess {
          partnerParentId
          partnerId
          name
          role
        }
      }
    }
  }
`);

export function getDriversListPage({
  companyId,
  departmentId,
  driverStatus,
  searchQuery,
  page,
  size,
}: {
  companyId: string;
  departmentId?: string;
  driverStatus?: Maybe<DriverStatus>;
  searchQuery?: string;
  page?: number;
  size?: number;
}) {
  return useQuery(GET_DRIVERS_LIST_PAGE_QUERY, {
    companyId,
    partnerId: departmentId,
    status: driverStatus,
    query: searchQuery,
    page,
    size,
  }).then((result) => result.cabinet?.company);
}

export type GetDriversListPageResult = NonNullable<NonNullable<GetDriversListPageQuery['cabinet']>['company']>;

export function driversGetDriverPage(v: DriversGetDriverPageQueryVariables) {
  const q = graphql(`
    query driversGetDriverPage($companyId: ID!, $partnerId: ID!, $driverId: ID!, $filter: OrdersQueryParams!) {
      cabinet {
        company(id: $companyId) {
          type
          driver(id: $driverId, partnerId: $partnerId) {
            id
            name
            phone
            created
            partnerCars {
              partner {
                id
                name
              }
              car {
                id
                number
              }
              limits {
                blocked
                day
                week
                month
                isQuantity
                fuels
                regions
                limitsType
                balance
              }
              isActive
            }
          }
          userAccess {
            partnerParentId
            partnerId
            name
            role
          }
          ordersNew(filter: $filter) {
            count
            items {
              id
              date
              status
              statusMessage
              user {
                phone
              }
              corp {
                driver {
                  name
                }
                car {
                  number
                }
              }
              station {
                name
                address
              }
              ... on OnlineOrder {
                item {
                  fuel {
                    name
                  }
                  quantityReport
                  quantityReq
                  costReport {
                    fact
                    corp
                  }
                  costReq {
                    fact
                    corp
                  }
                  prices {
                    fact
                    corp
                  }
                  pump
                }
              }
            }
            summary {
              name
              count
              quantity
              total
              discount
            }
          }
          departments {
            id
            name
            children {
              id
              name
              children {
                id
                name
              }
            }
          }
        }
      }
    }
  `);

  return useQuery(q, v).then((r) => r);
}

export function driversRefreshDriverPage(v: DriversRefreshDriverPageQueryVariables) {
  const q = graphql(`
    query driversRefreshDriverPage($companyId: ID!, $partnerId: ID!, $driverId: ID!) {
      cabinet {
        company(id: $companyId) {
          type
          driver(id: $driverId, partnerId: $partnerId) {
            id
            name
            phone
            created
            partnerCars {
              partner {
                id
                name
              }
              car {
                id
                number
              }
              limits {
                blocked
                day
                week
                month
                isQuantity
                fuels
                regions
                limitsType
                balance
              }
              isActive
            }
          }
          userAccess {
            partnerParentId
            partnerId
            name
            role
          }
          departments {
            id
            name
            children {
              id
              name
              children {
                id
                name
              }
            }
          }
        }
      }
    }
  `);

  return useQuery(q, v).then((r) => r);
}

export async function driversAddDriver(v: DriversAddDriverMutationVariables) {
  const q = graphql(`
    mutation driversAddDriver($companyId: ID!, $driver: DriverData!) {
      addDriver(companyId: $companyId, data: $driver) {
        id
      }
    }
  `);

  return useQuery(q, v);
}

export async function getDriverBalanceHistory(v: GetDriverBalanceHistoryQueryVariables) {
  const q = graphql(`
    query getDriverBalanceHistory(
      $companyId: ID
      $partnerId: ID!
      $contractId: ID
      $driverId: ID!
      $filter: QueryParams!
    ) {
      cabinet {
        company(id: $companyId, partnerId: $partnerId, contractId: $contractId) {
          driver(id: $driverId, partnerId: $partnerId) {
            balanceHistory(filter: $filter) {
              items {
                total
                date
              }
              count
            }
          }
        }
      }
    }
  `);

  return useQuery(q, v);
}

export type Drivers = GetDriversListPageResult['drivers'] | GetDriversResult['drivers'];
export type DriversItem = Drivers['items'][number];
